

























































import GET_OUTLET_ADDRESS from "@/modules/outlets/graphql/GetOutletAddress.gql";
import CREATE_OUTLET_ADDRESS from "@/modules/outlets/graphql/CreateOutletAddress.gql";
import UPDATE_OUTLET_ADDRESS from "@/modules/outlets/graphql/UpdateOutletAddress.gql";
import UIState from "@/modules/outlets/state/ui";
import {
  LoadingZone,
  FormControl,
  Button,
  Toast,
  Input,
  GoogleMaps,
} from "@/design-system";
import { omit } from "ramda";
import { defineComponent, ref, onMounted } from "@vue/composition-api";
import { useFormErrors } from "@/composable";
import { translate } from "@/i18n";
import { OrderingModes } from "@/modules/outlets/enums";

export default defineComponent({
  name: "Address",
  components: {
    LoadingZone,
    FormControl,
    Input,
    Button,
    GoogleMaps,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    outletId: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const { isAddressCreated, setAddressId } = UIState;

    const address = ref<Record<string, any>>({});
    const country = ref<String>("");

    // TODO: replace by apollo-composable when available
    const isAddressLoading = ref<boolean>(false);
    const fetchAddress = async () => {
      isAddressLoading.value = true;

      const { data } = await context.root.$apollo.query({
        query: GET_OUTLET_ADDRESS,
        variables: {
          outletId: props.outletId,
        },
      });

      isAddressLoading.value = false;

      const fetchedAddress = data.outlet?.address ?? {};
      const area = fetchedAddress.area ?? {};

      country.value = data.outlet?.business?.country ?? "AE";

      address.value =
        omit(["__typename"], {
          ...fetchedAddress,
          ...{
            area: area.name,
            city: area.city,
          },
        }) ?? {};
    };

    onMounted(async () => {
      fetchAddress();
    });

    // init form errors
    const {
      clearErrors,
      setErrors,
      messageErrorFor,
      hasErrorFor,
      gqlValidationErrorsExtractor,
    } = useFormErrors();

    const isSavingAddress = ref<boolean>(false);

    const submitForm = async () => {
      isSavingAddress.value = true;

      try {
        await (isAddressCreated.value ? editAddress() : createAddress());

        clearErrors();

        new Toast().create({
          type: "success",
          title: translate("label.success"),
          text: translate("c.outlets.success_message"),
        });
      } catch (error: any) {
        setErrors(gqlValidationErrorsExtractor(error));

        new Toast().create({
          type: "error",
          title: translate("label.oops"),
          text: translate("c.outlets.error_message"),
        });
      } finally {
        isSavingAddress.value = false;
      }
    };

    const createAddress = async () => {
      const { data } = await context.root.$apollo.mutate({
        mutation: CREATE_OUTLET_ADDRESS,
        errorPolicy: "none",
        variables: getAddressAttributes(),
      });

      setAddressId(data.createOutletAddress.id);

      context.root.$router.push({
        name: "outlets.ordering-services.delivery",
        params: {
          businessId: props.businessId,
          outletId: props.outletId,
        },
      });

      return data;
    };

    const editAddress = async () => {
      const { data } = await context.root.$apollo.mutate({
        mutation: UPDATE_OUTLET_ADDRESS,
        errorPolicy: "none",
        variables: getAddressAttributes(),
      });

      return data;
    };

    const getAddressAttributes = () => ({
      outletId: props.outletId,
      addressId: address.value.id,
      area: address.value.area,
      location: address.value.location,
      lat: address.value.lat,
      lng: address.value.lng,
    });

    interface LatLng {
      lat: number;
      lng: number;
    }

    const updateCoordinates = (coordinates: LatLng): void => {
      address.value.lat = coordinates.lat;
      address.value.lng = coordinates.lng;
    };

    return {
      // state
      address,
      isAddressLoading,
      isSavingAddress,
      country,

      // actions
      submitForm,
      messageErrorFor,
      hasErrorFor,
      updateCoordinates,
    };
  },
});
